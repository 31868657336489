<template>
  <main
    class="flex flex-col justify-center items-center px-6 py-40 w-full leading-tight text-center whitespace-nowrap max-md:px-5 max-md:py-24 max-md:max-w-full bg-white dark:bg-gray-900 transition-colors duration-200"
    style="background-image: url('/logo.png'); background-size: cover; background-position: center;"
  >
      <h1 class="text-[96px] font-bold text-gray-800 dark:text-gray-100 max-md:text-[48px] transition-colors duration-200">
        Quantify
      </h1>
      <h1 class="mt-2 text-[96px] font-semibold text-gray-600 dark:text-gray-300 max-md:text-[24px] transition-colors duration-200">
        Jiujitsu
      </h1>
  </main>
</template>
